import Vue from 'vue';
import { mapGetters } from 'vuex';

Vue.mixin({
    head() {
        let baseUrl = this.getBaseUrl();
        return {
            meta: [
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: `${baseUrl}/images/ogimage.jpg`
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            isMobile: 'getIsMobile',
            screenWidth: 'getCurrentScreenWidth'
        }),
        imageBasedStyle() {
            let width = this.photoWidth.length ? this.photoWidth[this.isMobile ? 1 : 0] : this.photoWidth;
            let height = this.photoHeight.length ? this.photoHeight[this.isMobile ? 1 : 0] : this.photoHeight;
            width = this.isMobile ? `${(width / 375) * 100}vw` : `${width}px`;
            height = this.isMobile ? `${(height / 375) * 100}vw` : `${height}px`;
            return {
                width, height
            }
        },
        widthBase() {
            let resolutions = [[320, 767], [768, 1365], [1366, 1918], [1919, 10000]];
            let width = this.screenWidth;
            let interval = resolutions.find((v) => width >= v[0] && width <= v[1]);
            return (interval && interval[0]) || null;
        }
    },
    methods: {
        onResize() {
            this.$store.commit('setIsMobile',  window.innerWidth < 768);
            this.$store.commit('setCurrentScreenWidth', window.innerWidth);
        },
        getBaseUrl() {
            return `https://${process.env.baseHost}`;
        },
        getLocalized(path, property) {
            let m = this.$t(`${path}.${property}`);
            return (m && (m.length || !m.startsWith(path))) ? m : null;
        },
        getLocalizedCollection(localsBase, property) {
            return  this.getLocalized(localsBase, property) || [];
        }
    },
});
